import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const conjugaisoncontemporaine = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Conjugaison contemporaine" />
    <h3 className='underline-title'>Conjugaison contemporaine</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Je vole haut<br />
        <span className="par-starting"></span>j'ai des ailes rongées<br />
      </p>
      <p>
        Tu entends tout<br />
        <span className="par-starting"></span>tu as l'oreille dure<br />
      </p>
      <p>
        Il a la constipation<br />
        <span className="par-starting"></span>son ventre coule<br />
      </p>
      <p>
        Nous crions fort<br />
        <span className="par-starting"></span>nous somme inaudibles<br />
      </p>
      <p>
        Vous voyez clair<br />
        <span className="par-starting"></span>vous êtes myopes<br />
      </p>
      <p>
        Ils sont trop riches<br />
        <span className="par-starting"></span>il leur manque un <span className="black-bold">sengi</span><br />
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 9 novembre 1973</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default conjugaisoncontemporaine
